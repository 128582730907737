import React, { useEffect, useState } from "react";
import "./Footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTwitter, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

function getIconByName(name) {
  if (!name) return null; // 检查 name 是否为空或者未定义
  switch (name.toLowerCase()) {
    case 'facebook':
      return faFacebook;
    case 'twitter':
      return faTwitter;
    case 'instagram':
      return faInstagram;
    case 'youtube':
      return faYoutube;
    default:
      return null;
  }
}

function Footer({ apiUrl }) {
  const [footerData, setFooterData] = useState({ description: '', socialLinks: [] });

  useEffect(() => {
    // 从后端获取 Footer 数据
    const fetchFooterData = async () => {
      try {
        const res = await fetch(apiUrl);
        const data = await res.json();
        if (data) {
          setFooterData(data);
        }
      } catch (error) {
        console.error("获取 Footer 数据时出错: ", error);
      }
    };
    
    fetchFooterData();
  }, [apiUrl]);

  return (
    <footer className="footer">
      <div className="footer-content">
        <p>{footerData.description}</p>
        <div className="social-links">
          {footerData.socialLinks.length > 0 ? (
            footerData.socialLinks.map((link, index) => (
              <a key={index} href={link.url} target="_blank" rel="noopener noreferrer">
                {getIconByName(link.name) ? (
                  <FontAwesomeIcon icon={getIconByName(link.name)} />
                ) : (
                  <img src={link.icon} alt={link.name} />
                )}
              </a>
            ))
          ) : (
            <p>กำลังโหลดลิงก์โซเชียล...</p>
          )}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
