import React, { useEffect, useState } from "react";
import "./FAQSection.css";

function FAQSection({ apiUrl }) {
  const [faqs, setFaqs] = useState([]);
  const [expandedQuestion, setExpandedQuestion] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    // 从后端获取 FAQ 数据
    fetch(apiUrl)
      .then((res) => {
        if (!res.ok) {
          throw new Error(`请求失败，状态码：${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        if (Array.isArray(data)) {
          setFaqs(data);
        } else {
          setFaqs([]);
        }
      })
      .catch((error) => {
        console.error("获取 FAQ 数据时出错: ", error);
        setError("无法加载常见问题，请稍后再试");
      });
  }, [apiUrl]);

  const toggleFAQ = (index) => {
    setExpandedQuestion(expandedQuestion === index ? null : index);
  };

  return (
    <div className="faq-section">
      <h2>คำถามที่พบบ่อย</h2>
      {error && <p className="error-message">{error}</p>}
      <div className="faq-list">
        {faqs.length > 0 ? (
          faqs.map((faq, index) => (
            <div key={faq._id} className="faq-item">
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                {faq.question}
                <span className="faq-toggle">{expandedQuestion === index ? "-" : "+"}</span>
              </div>
              {expandedQuestion === index && (
                <div className="faq-answer">
                  {faq.answer}
                </div>
              )}
            </div>
          ))
        ) : (
          !error && <p>ไม่มีคำถามที่พบบ่อยที่จะแสดง。</p>
        )}
      </div>
    </div>
  );
}

export default FAQSection;
