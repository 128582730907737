import React, { useState, useEffect } from "react";
import "./VideoIntroduction.css";

const videos = [
  {
    id: 1,
    src: "https://www.youtube.com/embed/CsIK6lNYiao",
    title: "ELEPH.TV - ความร่วมมือกับดิสนีย์",
  },
  {
    id: 2,
    src: "https://www.youtube.com/embed/xsPr9hLkSh0",
    title: "กลไกแพลตฟอร์มตั๋วภาพยนตร์ระดับโลก",
  },
  {
    id: 3,
    src: "https://www.youtube.com/embed/OfscYTZeZFI",
    title: "แนะนำการลงทุนภาพยนตร์ ELEPH",
  },
];

function VideoIntroduction() {
  const [isMobile, setIsMobile] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  // 检测是否为移动端
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize(); // 初始化
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNext = () => {
    setCurrentVideoIndex((prev) => (prev + 1) % videos.length);
  };

  const handlePrev = () => {
    setCurrentVideoIndex((prev) => (prev - 1 + videos.length) % videos.length);
  };

  return (
    <div className="video-introduction">
      <h2>ELEPH คืออะไร？</h2>
      <p>วิดีโอด้านล่างนี้จะแนะนำข้อมูลเกี่ยวกับ ELEPH และเนื้อหาที่น่าสนใจ：</p>

      {isMobile ? (
        // 手机端：单视频显示
        <div className="video-carousel">
          <iframe
            src={videos[currentVideoIndex].src}
            title={videos[currentVideoIndex].title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>{videos[currentVideoIndex].title}</p>
          <div className="carousel-controls">
            <button onClick={handlePrev}>⬅️ ก่อนหน้า</button>
            <button onClick={handleNext}>➡️ ถัดไป</button>
          </div>
        </div>
      ) : (
        // 电脑端：显示所有视频
        <div className="video-grid">
          {videos.map((video) => (
            <div className="video-item" key={video.id}>
              <iframe
                src={video.src}
                title={video.title}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
              <p>{video.title}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default VideoIntroduction;
