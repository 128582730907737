import React, { useState, useEffect } from "react";
import "./TopBar.css";
import logoDesktop from "../assets/desktop-logo.png";
import logoMobile from "../assets/mobile-logo.png";

function TopBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDayTheme, setIsDayTheme] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleTheme = () => {
    setIsDayTheme(!isDayTheme);
  };

  useEffect(() => {
    const setInitialTheme = () => {
      const now = new Date();
      const localTimeOffset = now.getTimezoneOffset();
      const thailandTimeOffset = 7 * 60;
      const thailandTime = new Date(now.getTime() + (thailandTimeOffset - localTimeOffset) * 60000);
      const hours = thailandTime.getHours();
      const isDayTime = hours >= 6 && hours < 18;
      setIsDayTheme(isDayTime);
    };

    setInitialTheme();
  }, []);

  useEffect(() => {
    document.body.className = isDayTheme ? "day-theme" : "night-theme";
  }, [isDayTheme]);

  // 点击页面其他区域关闭菜单
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (!e.target.closest(".navigation") && !e.target.closest(".hamburger-menu")) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  return (
    <div className="top-bar">
      <img src={logoDesktop} alt="Desktop Logo" className="logo logo-desktop" />
      <img src={logoMobile} alt="Mobile Logo" className="logo logo-mobile" />

      <div className="hamburger-menu" onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <nav className={`navigation ${isMenuOpen ? "open" : ""}`}>
        <a href="#home">Home</a>
        <a href="#about">About</a>
        <a href="#services">Services</a>
        <a href="#contact">Contact</a>
      </nav>

      <button className="theme-toggle-button" onClick={toggleTheme}>
        {isDayTheme ? "🌙 โหมดกลางวัน" : "☀️ โหมดกลางคืน"}
      </button>
    </div>
  );
}

export default TopBar;
