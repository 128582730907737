import React from "react";
import TopBar from "../components/TopBar";
import HeroBanner from "../components/HeroBanner";
import CompanyInfoSection from "../components/CompanyInfoSection";
import FAQSection from "../components/FAQSection";
import Footer from "../components/Footer";
import BackgroundAnimation from "../components/BackgroundAnimation";
import PartnerLogoSlider from "../components/PartnerLogoSlider";
import VideoIntroduction from "../components/VideoIntroduction";
import MoviesSection from "../components/MoviesSection"; // 引入 MoviesSection
import API_BASE_URL from "../config";

function HomePage() {
  return (
    <div id="home-page">
      <BackgroundAnimation />
      <TopBar />
      <HeroBanner />
      <CompanyInfoSection />

      {/* 电影展示区 */}
      <MoviesSection title="ภาพยนตร์ที่กำลังเป็นที่นิยม" category="trending" />
      <MoviesSection title="กำลังจะเข้าฉาย" category="netflix-exclusive" />

      {/* 合作商家展示区 */}
      <PartnerLogoSlider />

      {/* 视频介绍区 */}
      <VideoIntroduction />

      {/* 常见问题区 */}
      <FAQSection apiUrl={`${API_BASE_URL}/faqs`} />

      {/* 页脚 */}
      <Footer apiUrl={`${API_BASE_URL}/footer`} />
    </div>
  );
}

export default HomePage;
