import React, { useRef, useState, useEffect } from 'react';
import Modal from "react-modal";
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import './MoviesSection.css';
import API_BASE_URL from '../config';

function useFetchMovies(category) {
  const [movies, setMovies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (category) {
      fetch(`${API_BASE_URL}/movies/${category}`)
        .then((res) => {
          if (!res.ok) throw new Error("网络响应失败");
          return res.json();
        })
        .then((data) => {
          if (Array.isArray(data)) setMovies(data);
          else throw new Error("返回的数据格式不正确");
        })
        .catch((error) => setError(error.message))
        .finally(() => setIsLoading(false));
    } else {
      setError("无效的分类参数");
      setIsLoading(false);
    }
  }, [category]);

  return { movies, isLoading, error };
}

function MovieSlider({ movies, openModal, isModalOpen }) {
  const containerRef = useRef(null);
  const leftButtonRef = useRef(null);
  const rightButtonRef = useRef(null);
  const [scrollAmount, setScrollAmount] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    if (containerRef.current) {
      const movieCard = containerRef.current.querySelector('.movie-card');
      if (movieCard) setCardWidth(movieCard.offsetWidth + 20);
    }
  }, [movies]);

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const handleScroll = () => {
        const maxScrollLeft = container.scrollWidth - container.clientWidth;

        // 如果滑动位置大于0，则显示左侧按钮
        if (container.scrollLeft > 0) {
          leftButtonRef.current.style.display = 'block';
        } else {
          leftButtonRef.current.style.display = 'none';
        }

        // 如果滑动位置到达最右侧，则隐藏右侧按钮
        if (container.scrollLeft >= maxScrollLeft) {
          rightButtonRef.current.style.display = 'none';
        } else {
          rightButtonRef.current.style.display = 'block';
        }

        setScrollAmount(container.scrollLeft);
      };

      container.addEventListener('scroll', handleScroll);
      handleScroll(); // 初始化按钮显示状态

      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [movies]);

  const handleScroll = (direction) => {
    if (isScrolling) return;
    setIsScrolling(true);

    if (containerRef.current) {
      const container = containerRef.current;
      const maxScroll = container.scrollWidth - container.offsetWidth;
      const newScrollAmount = Math.min(
        Math.max(scrollAmount + direction * cardWidth * 2, 0),
        maxScroll
      );
      setScrollAmount(newScrollAmount);
      container.scrollTo({ left: newScrollAmount, behavior: 'smooth' });
    }

    setTimeout(() => setIsScrolling(false), 500);
  };

  return (
    <div className="movies-grid">
      {!isModalOpen && (
        <button
          className="nav-button left"
          ref={leftButtonRef}
          onClick={() => handleScroll(-1)}
          disabled={isScrolling || scrollAmount === 0}
        >
          <FaArrowLeft />
        </button>
      )}
      <div className="movies-container" ref={containerRef} style={{ display: 'flex', overflow: 'hidden' }}>
        {movies.map((movie, index) => (
          <div key={index} className="movie-card" onClick={() => openModal(movie)}>
            <img src={movie.thumbnailUrl} alt={movie.title} />
            <h3>{movie.title}</h3>
          </div>
        ))}
      </div>
      {!isModalOpen && (
        <button
          className="nav-button right"
          ref={rightButtonRef}
          onClick={() => handleScroll(1)}
          disabled={isScrolling || (containerRef.current && scrollAmount >= containerRef.current.scrollWidth - containerRef.current.offsetWidth)}
        >
          <FaArrowRight />
        </button>
      )}
    </div>
  );
}

function MoviesSection({ title, category }) {
  const { movies, isLoading, error } = useFetchMovies(category);
  const [selectedMovie, setSelectedMovie] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (movie) => {
    setSelectedMovie(movie);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedMovie(null);
  };

  return (
    <div className="movies-section">
      <h2>{title}</h2>
      {isLoading ? (
        <p>กำลังโหลด...</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
        <div className="slider-wrapper">
          {movies.length > 0 ? (
            <MovieSlider movies={movies} openModal={openModal} isModalOpen={isModalOpen} />
          ) : (
            <p>ยังไม่มีข้อมูลภาพยนตร์。</p>
          )}
        </div>
      )}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Movie Details"
        ariaHideApp={false}
        className="movie-modal"
        overlayClassName="movie-modal-overlay"
      >
        {selectedMovie && (
          <div className="modal-content">
            <h2>{selectedMovie.title}</h2>
            <img src={selectedMovie.detailImageUrl} alt={selectedMovie.title} />
            <p>{selectedMovie.description}</p>
            <button onClick={closeModal} className="close-modal-button">关闭</button>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default MoviesSection;
