// CompanyInfoSection.js
import React, { useState } from 'react';
import './CompanyInfoSection.css';
import licenseImage from '../assets/about-eleph.png'; // 引入营业执照图片

function CompanyInfoSection() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // 打开模态窗口
  const openModal = () => setIsModalOpen(true);

  // 关闭模态窗口
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className="company-info-section">
      {/* 进入官网按钮 */}
      <button 
        className="info-button" 
        onClick={() => window.location.href = 'https://eleph.vip'}
      >
        🌐 เข้าสู่เว็บไซต์
      </button>

      {/* 关于公司按钮 */}
      <button className="info-button" onClick={openModal}>
        🏆 เกี่ยวกับบริษัท
      </button>

      {/* 模态窗口 */}
      {isModalOpen && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeModal}>✕</button>
            <h2>เกี่ยวกับ ELEPH</h2>
            <p>Elephant Media Co., Ltd. เป็นบริษัทสื่อ ครบวงจรที่รวบรวมภาพยนตร์และโทรทัศน์ เอเจนซี่ลิขสิทธิ์ โปรโมทภาพยนตร์มิเดีย โปรโมทบ็อกซ์ออฟฟิศ เผยแพร่สื่อ วางแผนโปรโมท และจัดจำหน่ายทำการตลาด ทาง ELEPH เป็นบริษัทที่เริ่มต้นใหม่ และทีมงาน R&D ได้ก่อตั้งขึ้นในปี 2018 และกลุ่มบริษัทที่อยู่เบื้องหลังมีประสบการณ์การดำเนินงานอย่างเชี่ยวชาญ</p>
            <img src={licenseImage} alt="营业执照" className="license-image" />
          </div>
        </div>
      )}
    </div>
  );
}

export default CompanyInfoSection;
